<template>
  <ThankYouPage />
</template>

<script>
import AbstractPage from 'chimera/all/components/page/AbstractPage'
import ThankYouPage from 'chimera/all/themes/blueflow/page/ThankYou/ThankYouPage'

export default {
  components: {
    ThankYouPage,
  },

  extends: AbstractPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Preventivo completato',
      headDescription:
        'Inserisci il tuo codice postale e confronta 6 preventivi gratuiti dalle migliori aziende della tua zona. Risparmia fino al 40% ✓100% gratis!',
      path: '/richieste-di-preventivi/preventivo-completato',
    }
  },
}
</script>
